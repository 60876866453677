<template>
  <div class="res-container">
    <banner :banner-list="bannerList" />
    <div class="res-nav">
      <ul class="res-nav-content van-hairline--bottom">
        <router-link active-class="active" tag="li" to="/residential/rIntroduce">系统介绍</router-link>
        <router-link active-class="active" tag="li" to="/residential/rOffer">系统报价</router-link>
      </ul>
    </div>
    <div class="res-main">
      <router-view></router-view>
    </div>
  </div>
</template>

<script>
  import Banner from '@/components/banner/Banner.vue';
  export default {
    data() {
      return {
        bannerList: {
          src: '/global/residential/banner.png',
          list: [
            {
              icon: 'guobiao',
              title: '紧跟国标规范',
              content: '以养老机构实际管理和服务需求为出发点，按照国标《养老机构服务安全基本规范》和《长期护理失能等级评估标准》设计研发。'
            },
            {
              icon: 'xinxi',
              title: '信息化、无纸化',
              content: '助力养老机构的护理管理、健康数据管理、评估管理、床位管理、员工管理等完全实现信息化、无纸化，简化工作流程，提高工作效率。'
            },
            {
              icon: 'duoduan',
              title: '信息平台多端适配',
              content: '信息平台多端（护士站显示屏、护士/护工平板、老人床前平板、老人房前平板、员工手机端、家属手机端、数据可视化大屏等）适配。'
            }
          ]
        },
      };
    },
    components: {
      Banner
    }
  }
</script>

<style lang="scss" scoped>
  .res-container {
    .res-nav {
      margin-top: 2.12rem;
      .res-nav-content {
        display: flex;
        width: 100%;
        > li {
          height: .44rem;
          width: 50%;
          text-align: center;
          line-height: .44rem;
          font-size: .18rem;
          font-weight: 500;
          color: rgba(0, 0, 0, 0.85);
          position: relative;
        }
        .active {
          color: #00ACF6;
          &::after {
            content: '';
            width: .32rem;
            height: .02rem;
            background-color: #00ACF6;
            position: absolute;
            bottom: 0;
            left: 50%;
            margin-left: -.16rem;
          }
        }
      }
    }
  }
</style>